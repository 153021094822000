import { SET_LANGUAGE } from './types';

export const saveLanguage = (language) => ({
    type: SET_LANGUAGE,
    language
});

export const setLanguage = (language) => (dispatch) => {
    localStorage.setItem("i18nextLng", JSON.stringify(language))
    dispatch(saveLanguage(language));
}
