import React, { Component } from 'react'
import { Dropdown } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { find } from 'lodash'
import { connect } from 'react-redux';
import { setLanguage } from 'shared/services/i18n';


import './langSelect.scss'

class LangSelect extends Component {
    state = {
        lang: { code: 'pt', label: 'PT' },
        langs: [{ code: 'en', label: 'EN' }, { code: 'pt', label: 'PT' },{ code: 'fr', label: 'FR' }]
    }

    changeLanguage = (event) => {
      const languageSelected = event.target.name;
      this.props.setLanguage(languageSelected);

      const { i18n } = this.props;
        i18n.changeLanguage(languageSelected);
        this.setState(prev => ({ ...prev, lang: find(prev.langs, { code: localStorage.i18nextLng }) }));
   
      }

    componentDidMount() {
        this.setState(prev => {
          const language = find(prev.langs, { code: localStorage.i18nextLng });
          if (!language) {
            this.props.i18n.changeLanguage('en');
          }
          return ({ ...prev, lang: (localStorage.i18nextLng) && language ? language : { code: 'en', label: 'EN' } })
        });

    }

  render() {
    const { lang, langs } = this.state;

    return (
        <Dropdown className="langSelect lang-dropdown-style">
            <Dropdown.Toggle id="dropdown-basic">
            {lang.code.toUpperCase()}
            </Dropdown.Toggle>

            <Dropdown.Menu>
            <Dropdown.Item name={lang.code} onClick={this.changeLanguage}>{lang.label}</Dropdown.Item>
            {langs && langs.map((language,index) => (
            (language.code !== lang.code) && <Dropdown.Item key={index} name={language.code} onClick={this.changeLanguage}>{language.label}</Dropdown.Item>
            ))}
            </Dropdown.Menu>
        </Dropdown>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lang) => dispatch(setLanguage(lang)),
});

const LangSelectWrapper = new withTranslation()(LangSelect)

export default connect(null, mapDispatchToProps)(LangSelectWrapper);
