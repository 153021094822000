import axios from 'axios';
import { loggerKey } from '../../config'

function SendLogger(user, message, stackTrace, type) {
    try {
        const modelData = {
            message: message,
            stackTrace: JSON.stringify(stackTrace),
            applicationKey: loggerKey,
            level: type,
            user: JSON.stringify(user),
            additionalData: { app: 'Web' }
        }

        axios.post('http://logger.sparkleit.pt/api/v1.0/log', modelData).then((response) => {
        }).catch((err) => {
        })
    } catch (err) {
    }
}

export default SendLogger
