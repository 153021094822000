import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './translation-en'
import translationPT from './translation-pt'
import translationFR from './translation-fr'
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: translationEN
    },
    pt: {
        translation: translationPT
    },
    fr: {
        translation: translationFR
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: 'en',
        fallbackNS: 'common',
        ns: ['common', 'translation'],
        defaultNS: ["translation"],
        keySeparator: '.',
        nsSeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        debug: false,
    });

export default i18n;

