import { combineReducers } from 'redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import project from '../shared/services/projects/reducers';
import {images} from '../shared/services/images/reducers';
import hubConnection from '../shared/services/SignalR/reducers'

import {filters, getsearch} from '../shared/services/timeline/reducers';
import projectInfo from '../shared/services/projectInfo/reducers';
import language from '../shared/services/i18n/reducers';
import user from '../shared/services/security/reducers';

const rootReducer = combineReducers({ user, project, projectInfo, filters, language, getsearch, images, hubConnection });

export default createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
