import ReactEncrypt from 'crypto-js'

export const ENC_KEY = "6v9y$B&E(H+MbQeThWmZq4t7w!z%C*F-";

export function encrypt(plaintext) {
	return ReactEncrypt.AES.encrypt(JSON.stringify(plaintext), ENC_KEY).toString();
}

export function decrypt(cypher) {
	const bytes = ReactEncrypt.AES.decrypt(cypher, ENC_KEY);
	return JSON.parse(bytes.toString(ReactEncrypt.enc.Utf8))
}