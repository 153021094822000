import React, { Component } from 'react';

//Toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
//Toast
import { BlockComponent } from './shared/components/block';
import ErrorBoundary from './ErrorBoundary'
import Routes from "./Routes";

class App extends Component {

    render() {
        const { location } = this.props;

        return (
            <ErrorBoundary>
                <ToastContainer autoClose={4000} />
                <Routes location={location}/>
                <BlockComponent />
            </ErrorBoundary>
        )
    }
}

export default App;
