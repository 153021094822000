import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import './Header.scss';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
//import Notifications from './components/notifications/notifications';
import LangSelect from './components/language-selector/langSelect';
import { out } from 'react-icons-kit/entypo/out'
import { logout } from 'shared/services/security';
import { set_signalrStop } from 'shared/services/SignalR';
import Icon from 'react-icons-kit';
import { withRouter } from "react-router";
import { encrypt, decrypt } from 'shared/services/crypto/index';
import ProfileService from '../../../pages/profile/profileService'

const MainImage = styled.section`
  background-image: url(${props => props.src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30px;
  width: 30px;
  margin: 0px 10px 3px 10px;
  border-radius: 50%;
`
class Header extends Component {

	state = {
		project: {},
		activeUser: "Null",
		activeUserImg: "assets/img/default-user.png"
	}

	// componentWillMount() {
	// 	const project = JSON.parse(localStorage.getItem("project"));
	// 	if (project !== this.state.project) {
	// 		this.setState({ project: project });
	// 	}

	// 	const activeUser = decrypt(localStorage.getItem("activeUser"));
	// 	const realName = activeUser.userName
	// 	let userImage = "assets/img/default-user.png"

	// 	this.setState({ activeUser: realName, activeUserImg: activeUser.attachmentId })
	// }

	routeChange() {
		const path = `/`;
		this.props.history.push(path);
	}

	onLogout = () => {
		if (this.props.hubConnection.hubConnection) {
			this.props.set_signalrStop()
		}
		this.props.logout()
	}

	goToProfile() {
		const path = `/profile`;
		this.props.history.push(path);
	}

	render() {
		const { pathname } = this.props.location;
		let { name } = this.props.project;
		const { project, activeUser, activeUserImg } = this.state;
		const { t, user } = this.props;
		
		if (name == null) {
			name = ''
		}

		let imgProfile = "";

		if(user.attachmentId == null){
			imgProfile = "assets/img/default-user.png";
		}else{
			imgProfile = `${user.attachmentId}.png?format=png`;
		}

		return (
			<Row className="header">
				<Col xs={12} md={3}>
					<img onClick={() => this.routeChange()} className="header-logo" src="assets/img/DCA_logo.png" alt="LOGO" />
				</Col>

				<Col className="header-title" xs={12} md={6}>
					{(name !== '' && pathname === '/project' ?
						(name ? name : project.name)
						:
						pathname !== '/' && pathname !== "/404" && pathname !== "/info_dca" && t(`HEADER.${pathname.slice(1).toUpperCase()}`)
					)}
				</Col>

				<Col xs={12} md={3}>

					<div className="header-icons">

						<LangSelect />

						{/*{pathname != '/'  && <Notifications/>}*/}

						{/*<div className="collapse-icon"><Icon size={32} icon={androidMenu}/></div>*/}
						<div className="name-icon-container" onClick={() => this.goToProfile()}>

							{/*<img className="user-icon" src={activeUserImg} alt="ICON" />*/}
							<MainImage src={imgProfile} />
							<div className="user-name-box">{user.userName}</div>

						</div>
						<div className="logout-icon gray-hover-icon-inButton" onClick={() => this.onLogout()}><Icon size={12} icon={out} /></div>
					</div>
				</Col>
			</Row>
		)
	}
}


const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(logout()),
	set_signalrStop: () => dispatch(set_signalrStop())
});


function mapStateToProps({ project, hubConnection, user }) {
	return { project, hubConnection, user };
}
const HeaderWraper = new withTranslation()(Header)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderWraper))
