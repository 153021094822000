import { api } from 'shared/services/interceptors/Api';

export default {
    // TODO change ID
    updatePassword: (model) => api.post('api/auth/change-password', model),
    //resetPassword: (model) => api.post('api/auth/reset-password', model),
    updateNotifications: (items) => api.put(`api/notification/updatenotifications`, items),
    //GetImageProfile
    getUserConfig: () => api.get(`api/userconfiguration`),
    //UpdateUserConfig
    sendImageProfile: (model) => api.put('api/userconfiguration/createupdateuserimg', model , {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
    })

}
