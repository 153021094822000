import axios from 'axios';
import { BlockService } from '../../components/block';
import { encrypt, decrypt } from 'shared/services/crypto/index';
import SendLogger from '../../controllers/SendLogger';

const getToken = () => localStorage.access;

const apiCaller = axios.create();

apiCaller.interceptors.request.use(
    config => {
		const cfg = config;

		if (!cfg.headers.Authorization) {
			const accessToken = getToken();
			let token = undefined;

			if (accessToken != null) {
				token = decrypt(accessToken);
			}

            if (token) {
                const newToken = token
                cfg.headers.Authorization = `Bearer ${newToken.access_token}`;
            }
        }
        BlockService.show();
        return cfg;
    },
    error => Promise.reject(error)
);

apiCaller.interceptors.response.use(
    response => {
        BlockService.hide();
        return response.data;
    },
    error => {
        BlockService.hide();

        if (error != null && error.response != null && error.response.status === 401) {
            //alert("Error 401 - Unauthorized, sending back to login")
            localStorage.clear()
            window.location.href = "/login";
        }

        //Send error to logger
        const status = error.response ? error.response.request.status : '';
        const statusText = error.response ? error.response.request.statusText : '';
        const url = error.response ? error.response.config.url : '';
        const method = error.response ? error.response.config.method : '';
        const data = error.response ? error.response.config.data : null
        const activeUser = localStorage.getItem("activeUser") ? decrypt(localStorage.getItem("activeUser")) : '';

        const message = `Response Error - ${status} (${statusText})`
        const stackTrace = `Method: ${method}; URL: ${url};${data ? ` Data: ${data};` : ''}`

        SendLogger(activeUser.userName || '', message, stackTrace, 'Error');

        return Promise.reject(error)
    }
);

export const api = apiCaller;

export const buildParams = (params) => {
    const query = Object.keys(params).map(k => `${encodeURIComponent(k)  }=${  encodeURIComponent(params[k])}`).join('&');
    return query ? `&${query}`: '';
}
