import React from 'react';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import SendLogger from './shared/controllers/SendLogger';
import { decrypt } from 'shared/services/crypto/index';

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      const activeUser = localStorage.getItem("activeUser") ? decrypt(localStorage.getItem("activeUser")) : '';
      const length = 200

      let errorMessage = `${error}`
      errorMessage = errorMessage.substring(0, length);
      if (errorMessage.length == length) {
        errorMessage = errorMessage.concat('...')
      }

      let componentStack = `${errorInfo && errorInfo.componentStack ? errorInfo.componentStack : ''}`
      componentStack = componentStack.substring(0, length);
      if (componentStack.length == length) {
        componentStack = componentStack.concat('...')
      }

      SendLogger(activeUser.userName || '', "UI Error", `Error: ${errorMessage}; componentStack: ${componentStack}`, 'Error');
    }
  
    goBackHandler = () => {
      this.setState({hasError: !this.state.hasError})
    }

    render() {
      if (this.state.hasError) {
        return (
          <div className="something-went-wrong">
            <div>
              <div className="message">{this.props.t("COMMON.MESSAGE.SOMETHING_WENT_WRONG")}</div>
              <button className="generic-button action-button" type="submit" onClick={this.goBackHandler}>{this.props.t("PAGES.STARTPAGE.GO_BACK")}</button>
            </div>
          </div>
        );
      }
  
      return this.props.children;
    }
  }
  export default withTranslation()(ErrorBoundary)