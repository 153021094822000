const blobStoreUri = "https://projectdca.blob.core.windows.net/app/";

let loggerKey;
if ( process.env.NODE_ENV && process.env.NODE_ENV === "production" ) {
    loggerKey = 'zScM1efokni4dKdT+PdAC9qoaCVkAD2iqaQxiqr5l086TgLjygO3ZcAmxpZm3iSx' //PROD KEY
} else {
    loggerKey = 'qRThSkK/sCmydxHgJXXY7jV9PLQEaLODjIH5AM+jk6NIZJOjoimRL5y0Mr4nX8YY' //DEV KEY
}

export { loggerKey, blobStoreUri };
