import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import 'App.scss';

import { ReplaySubject } from 'rxjs';

const blocking = new ReplaySubject(1);

export const BlockService = {
    isBlocking: () => {
        return blocking.asObservable();
    },
    show: () => {
        blocking.next(true);
    },
    hide: () => {
        blocking.next(false);
    }
}

export class BlockComponent extends Component {

    state = {
        blocking: false
    }

    componentDidMount() {
        BlockService.isBlocking()
        .subscribe((blocking) => {
            this.setState((state, props) => {
                if ( state.blocking !== blocking ) {
                    return {
                        blocking: blocking
                    }
                }
                return null;
            });
        });
    }

    render() {
        const { blocking } = this.state;

        return (
            <BlockUi tag="div" blocking={blocking}></BlockUi>
        )
    }
}
