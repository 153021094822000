import React from 'react'
import Header from '../components/Header/Header'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
const contentWrapperPublic = { backgroundColor: '#ecf0f5' };
const MainLayout = ({ children, isAuthenticated,location }) => (<div className="wrapper" style={isAuthenticated ? {} : contentWrapperPublic}>
    {/* TODO: Change the Validation of the user !isAuthenticated to isAuthenticated */}
    {!isAuthenticated && <Header location={location} />}
      {children}
  </div>
);

MainLayout.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return { isAuthenticated: !!state.user.token }
}

export default connect(mapStateToProps)(MainLayout)

