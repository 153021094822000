import { SET_FILTER } from './types';
import { SET_NAME } from './types';
import { SET_DATE } from './types';
import { SET_STATUS } from './types';
import { SET_ALL } from './types';


export function filters(state = {
	download: false,
	request: false,
	upload: false,
	agenda: false,
	message: false,
	report: false,
}, action = {}) {
	switch (action.type) {
		case SET_FILTER:
			return {
				...state,
				...action.filters
			};
		default: return state;
	}
};
export function getsearch(state = {
	search: '',
	inicial_date: '',
	end_date: '',
	status: ''
}, action = {}) {
	switch (action.type) {
		case SET_NAME:
			return {
				search: action.name
			};
		case SET_DATE:
			return {
				inicial_date: action.inicial_date,
				end_date: action.end_date
			};
		case SET_STATUS:
			return {
				status: action.status
			};
		case SET_ALL:
			return {
				inicial_date: action.inicial_date,
				end_date: action.end_date,
				status: action.status,
				search: action.name
			};
		default: return state;
	}
};
