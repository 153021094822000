import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';

const PublicRoute = ({isAuthenticated, component : Component, ...rest }) => (
    <Route {...rest} render={props => (!isAuthenticated || rest.path === "/privacy") ? <Component {...props}/> : <Redirect to="/"/>}/>
);

function mapStateToProps(state) {
    return { isAuthenticated : !!state.user.id };
}

export default connect(mapStateToProps)(PublicRoute)
