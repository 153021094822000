import { USER_LOGGED_IN, USER_LOGGED_OUT, USER_PROFILE_IMG } from './types';
import { api } from 'shared/services/interceptors/Api';
import { encrypt, decrypt } from 'shared/services/crypto/index';

export const userLoggedIn = (user) => ({
	type: USER_LOGGED_IN,
	user
});

export const userLoggedOut = () => ({
	type: USER_LOGGED_OUT,
	user: {}
});

export const login = (credentials) => api.post('/api/auth/token', credentials)
	.then(token => {
		const cypher = encrypt(token);
		localStorage.access = cypher;
	});

export const setProfile = (urlImg) => (dispatch) => dispatch({
	type: USER_PROFILE_IMG,
	urlImg
})

export const getProfile = async (dispatch) => await api.get('/api/auth/getprofile')
		.then((user) => {
			const cypher = encrypt(user);
			localStorage.activeUser = cypher
			dispatch(userLoggedIn(user))
		})
		.catch((error) => {
			console.log("deu erro: ", error)
			dispatch(logout());
		})

export const logout = () => (dispatch) => {
	localStorage.clear()
	dispatch(userLoggedOut());
}