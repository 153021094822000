import { SET_UPLOADED_FILE, SET_TABS, SET_CLEARNEWSPECSTATE } from './types';

export function images(state = {}, action = {}) {
    
    switch (action.type) {
        case SET_CLEARNEWSPECSTATE:
            return {
                ...state,
                clearNewSpecState: action.clearNewSpecState
            };
        case SET_UPLOADED_FILE:
            return {
                ...state,
                image: action.image
            };
        case SET_TABS:
            return {
                ...state,
                tabs: action.tabs
            };
        default: return state;
    }
};

