import React, { Suspense } from 'react';
import { Switch, Redirect } from "react-router-dom";

import AuthRoute from './shared/routes/AuthRoute';
import PublicRoute from './shared/routes/PublicRoute';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';


export default ({ location }) => {
    /*const isError = !!(
        location.state &&
        location.state.error
    )*/
    return (
        <Suspense fallback={<BlockUi tag="div" blocking='true'></BlockUi>}>
            <Switch>
                <PublicRoute location={location} path="/login" exact component={React.lazy(() => import('pages/login/Login'))} />
                <PublicRoute location={location} path="/recover-password" exact component={React.lazy(() => import('pages/login/RecoverPassword'))} />
                <PublicRoute location={location} path="/reset-password" exact component={React.lazy(() => import('pages/login/ResetPassword'))} />
                <PublicRoute location={location} path="/vr" exact component={React.lazy(() => import('pages/imagesPage/components/virtualReality_mobile'))} />
                <PublicRoute location={location} path="/createuser" exact component={React.lazy(() => import('pages/login/createNewUser/createNewUser'))} />
                <PublicRoute location={location} path="/privacy" exact component={React.lazy(() => import('pages/privacy/privacy'))} />

                <AuthRoute location={location} path="/" exact component={React.lazy(() => import('pages/startPage/startPage'))} />
                <AuthRoute location={location} path="/createproject" exact component={React.lazy(() => import('pages/startPage/createNewProject/createNewProject'))} />
                <AuthRoute location={location} path="/project" exact component={React.lazy(() => import('pages/startPage/indexProject'))} />
                <AuthRoute location={location} path="/history" exact component={React.lazy(() => import('pages/timeLine/timeLine'))} />
                <AuthRoute location={location} path="/details" exact component={React.lazy(() => import('pages/projectDetails/projectDetails'))} />
                <AuthRoute location={location} path="/communication" exact component={React.lazy(() => import('pages/communicationPage/communicationPage'))} />
                <AuthRoute location={location} path="/images" exact component={React.lazy(() => import('pages/imagesPage/imagesPage'))} />
                <AuthRoute location={location} path="/info" exact component={React.lazy(() => import('pages/info/info'))} />
                <AuthRoute location={location} path="/info_dca" exact component={React.lazy(() => import('pages/infoDca/info'))} />
                <AuthRoute location={location} path="/profile" exact component={React.lazy(() => import('pages/profile/profile'))} />
                <AuthRoute location={location} path="/404" exact component={React.lazy(() => import('pages/not-found/NotFound'))} />
                <AuthRoute location={location} path="/projects" exact component={React.lazy(() => import('pages/admin/project'))} /> 
                <AuthRoute location={location} path="/manager" exact component={React.lazy(() => import('pages/admin/manager'))} /> 
                <AuthRoute location={location} path="/promotor" exact component={React.lazy(() => import('pages/admin/promotor'))} />   
                <Redirect to="/404" />
            </Switch>
        </Suspense>
    )
};
