import { SIGNALR_ON, SIGNALR_STOP} from './types';
export default function hubConnection (state={}, action={}) {
    switch (action.type) {
        case SIGNALR_ON:
            return {
                ...state,
                hubConnection: action.hubConnection
            }
        case SIGNALR_STOP:
            state.hubConnection.stop()
            return {
                ...state,
                stop: action.stop
            }
        default: return state;
    }
};
