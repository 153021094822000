import { SIGNALR_ON, SIGNALR_STOP } from './types';
import { HubConnectionBuilder, HttpTransportType, LogLevel } from '@aspnet/signalr';
import { encrypt, decrypt } from 'shared/services/crypto/index';

export const signalrTurnOn = (hubConnection) => ({
	type: SIGNALR_ON,
	hubConnection
});

export const signalrStop = () => ({
	type: SIGNALR_STOP,
});

export const set_signalrTurnOn = (dispatch) => {

	//SIGNALR--------------
	const transport = HttpTransportType.LongPolling;
	const logger = LogLevel.None;
	const hubUrl = window.location.protocol + "//" + window.location.host + '/chat';
	const accessToken = decrypt(localStorage.getItem("access")).access_token

	const hubConnection = new HubConnectionBuilder()
		.withUrl(hubUrl, {
			transport,
			logger,
			accessTokenFactory: () => accessToken
		})
		.build();

	hubConnection.start()
	//---------------------

	dispatch(signalrTurnOn(hubConnection));
}

export const set_signalrStop = () => (dispatch) => {
	dispatch(signalrStop());
}

