import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import MainLayout from 'shared/layout/MainLayout'

const AuthRoute = ({location, isAuthenticated, component : Component, ...rest }) => (
    <Route {...rest} render={
        props => isAuthenticated ? 
            <MainLayout location={location} path="/">
                <Component {...props}/>
            </MainLayout> 
        : <Redirect to="/login"/>}/>
);

function mapStateToProps(state) {
    // before  return {isAuthenticated : !!state.user.token};
    return {isAuthenticated : !!state.user.id};
}

export default connect(mapStateToProps)(AuthRoute)
