import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import $ from 'jquery';
//import 'bootstrap/dist/css/bootstrap.css';

import 'bootstrap/dist/css/bootstrap.min.css';

//Transalations
import './shared/translations/i18n';
//Transalations

//Redux
import store from './reducer/rootReducer';
import { Provider } from 'react-redux';
//Redux

//Routes
import { BrowserRouter, Route } from 'react-router-dom'
//Routes
import { getProfile } from 'shared/services/security';
import { set_signalrTurnOn } from 'shared/services/SignalR';

window.jQuery = $;
require('bootstrap');

const setIndex = () => {
    ReactDOM.render(
        <BrowserRouter>
            <Provider store={store}>
                    <Route path="/" component={App} />
            </Provider>
        </BrowserRouter>
    , document.getElementById('root'));
}

if ( localStorage.access ) {
    set_signalrTurnOn(store.dispatch)
    console.log(store.dispatch)
    getProfile(store.dispatch)
    .then((user) => {
        setIndex();
    });
}
else {
    setIndex();
}

serviceWorker.unregister();
